
import React from "react";
import { API } from "aws-amplify";
import { Button, Box, Typography } from "@material-ui/core";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";

export function CreateInvoiceButton({ ticket, className, history }) {
  return (
    <Button
      style={{ marginLeft: "auto", width: "100%", margin: "20px 0px 20px 0px" }}
      variant="contained"
      color="primary"
      size="large"
      className={className}
      onClick={() => history.push('/create_invoice?ticket_id=' + ticket.ticket_id)}
    >
      Create Invoice and Close
    </Button>
  );
}

export function BackButton({ history }) {
  return (
    <Box
      style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
      onClick={() => history.goBack()}
    >
      <BackIcon />
      <Typography style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
        Back
			</Typography>
    </Box>
  );
};

export function DeleteTicketButton({ ticket, className, history }) {
  return (
    <Button
      style={{ marginLeft: "auto", marginTop: "10px", marginRight: "10px" }}
      variant="contained"
      color="secondary"
      size="large"
      className={className}
      onClick={() => {
        if (window.confirm(`Are you sure you would like to delete this ticket?`)) {
          API.del('api', 'delete_ticket/' + ticket.ticket_id).then(_ => {
            history.push("/tickets");
          })
        }
      }}
    >
      Delete
    </Button>
  );
}
