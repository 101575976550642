import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import classnames from "classnames";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import UserProvider from "./context/User"
import UsersProvider from "./context/Users"
import CustomersProvider from "./context/Customers"

// styles
import useStyles from "./styles";

// components
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";


function App(props) {
	const classes = useStyles();
	const theme = useTheme();
	// state vars
	const [isSmall, setIsSmall] = useState(null);
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [isAuthenticated, userHasAuthenticated] = useState(false);
	const [cognito, setCognito] = useState(null);
	const [filters, setFilters] = useState({ tickets: null });

	// init
	useEffect(() => {
		async function onLoad() {

			// get user session (if logged in)
			try {
				await Auth.currentSession();
				userHasAuthenticated(true);
			} catch (e) {
				if (e !== 'No current user') {
					console.log(e);
				}
			}
			setIsAuthenticating(false);

			// get init width and listen for resize
			getIsSmall();
			window.addEventListener("resize", () => getIsSmall());
		}

		onLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// get cognito data
	useEffect(() => {
		async function getCognitoData() {
			if (isAuthenticating) return;

			if (isAuthenticated) {
				const cognito = await Auth.currentAuthenticatedUser({ bypassCache: true });

				setCognito({
					UID: cognito.attributes['custom:uid']? cognito.attributes['custom:uid'] : cognito.attributes.sub,
					email: cognito.attributes.email,
				});
			} else {
				setCognito(null);
			}
		}

		getCognitoData();
	}, [isAuthenticated, isAuthenticating]);

	// check for small display (and force close sidebar)
	function getIsSmall() {
		const windowWidth = window.innerWidth;
		const breakpointWidth = theme.breakpoints.values.md;
		const isSmall = windowWidth < breakpointWidth;

		setIsSmall(isSmall);
		setIsSidebarOpen(!isSmall);
	}

	return (
		!isAuthenticating && (
			<UserProvider cognito={cognito}>
				<CustomersProvider>
					<UsersProvider>
						<div className={classes.root}>
							{isAuthenticated && (
								<React.Fragment>
									<Header props={{
										isSmall,
										isSidebarOpen,
										setIsSidebarOpen,
										userHasAuthenticated,
										history: props.history,
										cognito
									}} />
									<Sidebar props={{
										isSmall,
										isSidebarOpen,
										setIsSidebarOpen,
										cognito
									}} />
								</React.Fragment>
							)}
							<div className={classnames(classes.content)}>
								<Routes appProps={{
									isSmall,
									isAuthenticated,
									userHasAuthenticated,
									cognito,
									filters, setFilters
								}} />
							</div>
						</div>
					</UsersProvider>
				</CustomersProvider>
			</UserProvider>
		)
	);
}

export default withRouter(App);

