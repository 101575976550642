
import React from "react";
import {Grid, FormControl, Select, MenuItem, FormHelperText} from "@material-ui/core";

export default function CustomerSelect({ customers, customer, setCustomer}) {
  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Select
          fullWidth
          label="Account"
          value={customer.customer_id}
          defaultValue={""}
          onChange={(e) => {
            const acct = customers[e.target.value]
            setCustomer(acct);
          }}
        >
          {
            Object.values(customers).map((val) => {
              return <MenuItem
                key={val.customer_id}
                value={val.customer_id}
              >
                {`${val.name} (${val.customer_number}) `}
              </MenuItem>
            })
          }
        </Select>
        <FormHelperText>Select an account to edit.</FormHelperText>
      </FormControl>
    </Grid>
  );
}
