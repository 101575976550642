import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from 'moment';

const roles = {
  'customer': 'Account',
  'admin': 'Admin',
  'hauler': 'Hauler'
}

export function Notes({ticket}) {
	return (
		<Grid item xs={12} style={{ fontSize: "1.15rem" }}>

			<Typography style={{ fontWeight: "600", paddingRight: "3px" }}>
				Notes
			</Typography>

			{
				ticket.notes.map((note, idx) => (
					<div key={idx} style={{ wordBreak: "break-word", padding: '0px 0px 5px 10px' }}>
						<span style={{ fontWeight: "600" }}>
							{moment(Object.keys(note)[0].split('Z')[0] + 'Z').format('MM-DD-YY h:mma')}
							{Object.keys(note)[0].split('Z')[1] && roles[Object.keys(note)[0].split('Z')[1]] ?
								<>&nbsp;({roles[Object.keys(note)[0].split('Z')[1]]})&nbsp;</> :
								<>&nbsp;</>}
						</span>
						{Object.values(note)[0]}
					</div>
				))
			}

		</Grid>
	);
}
