import { API } from "aws-amplify";
import React from "react";

import { Button } from "@material-ui/core";
export function UpdateUserButton({ user, callback, disabled }) {
  return (
    <Button
      style={{ marginTop: "10px", marginRight: "10px" }}
      variant="contained"
      color="secondary"
      size="large"
      onClick={() => {
        API.post('api', 'update_user/' + user.user_id, { body: user }).then(_ => {
          callback();
        })
      }}
    >
      Update User Info
    </Button>
  );
}
