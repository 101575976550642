import { API } from "aws-amplify";
import React from "react";
import useStyles from "./styles";
import currency from "currency.js";

import { Button } from "@material-ui/core";
export function UpdateCustomerButton({ customer, callback }) {
  const classes = useStyles();
  return (
    <Button
      style={{ marginTop: "10px", marginRight: "10px" }}
      variant="contained"
      color="secondary"
      size="large"
      className={classes.backButton}
      onClick={() => {
        const updated = customer;
        
        // convert all the rates to decimal values
        Object.keys(updated.rates).forEach((key) => {
          updated.rates[key] = currency(customer.rates[key]).value;
        })

        // convert tax rate to decimal
        customer.tax_rate ? updated.tax_rate = currency(customer.tax_rate).value : updated.tax_rate = 0.8;

        API.post('api', 'update_customer/' + customer.customer_id, { body: updated }).then(_ => {
          callback();
        })
      }}
    >
      Update
    </Button>
  );
}

export function CancelButton({callback}){
  const classes = useStyles();
  return (
    <Button
      style={{ marginTop: "10px", marginRight: "10px" }}
      variant="contained"
      color="secondary"
      size="large"
      className={classes.backButton}
      onClick={() => {
        callback();
      }}
    >
      Cancel
    </Button>
  );

}
