import React, { useEffect, useState, useContext } from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { API } from "aws-amplify";
import moment from 'moment';

// libs
import { querystring } from "../../libs/querystring"

// components
import Loader from "../../components/Loader/Loader";
import { Button as Badge } from "../../components/Wrappers/Wrappers";
import { ItemListView } from "../../components/Items/ItemView";
import { CustomerInfo } from "../../components/Customer/CustomerInfo";
import { Notes } from "../../components/Ticket/Ticket";
import { CreateInvoiceButton, DeleteTicketButton, BackButton } from "../../components/Ticket/Buttons";

// styles
import useStyles from "./styles";

import { userContext } from "../../context/User";


export default function Ticket(props) {
  const classes = useStyles();
  const user = useContext(userContext);

  //state
  const [ticket, setTicket] = useState(null);
  const [showCustomer, setShowCustomer] = useState(false);
  const [customer, setCustomer] = useState(null);

  // badge colors
  const states = {
    Open: "secondary",
    Pending: "info",
    Closed: "primary"
  };

  useEffect(() => {
    // get ticket id from url
    const ticket_id = querystring('ticket_id');
    // fetch ticket from ddb
    API.get('api', 'tickets/' + ticket_id).then(resp => {
      setTicket(resp);
    }).catch(() => console.log('here')); // TODO: handle error
  }, []);

  useEffect(() => {
    if (!showCustomer || !user) return;

    const params = { queryStringParameters: { user_id: user.user_id } }

    API.get('api', 'customers/' + ticket.customer_id, params).then(resp => {
      setCustomer(resp);
    }).catch(() => console.log('here')); // TODO: handle error

  }, [showCustomer, ticket, user]);

  let color;
  if (ticket) {
    if (ticket.urgency === 'Urgent') {
      if (ticket.status === 'Open')
        color = 'warning';
      else if (ticket.status === 'Pending')
        color = 'success';
      else
        color = 'primary';
    } else {
      color = states[ticket.status];
    }
  }

  return (
    ticket && user ? (
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Pickup Request
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <div>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    Acct#
                  </span> {ticket.customer_number}
                  {!showCustomer && (
                    <span
                      style={{ cursor: 'pointer', fontSize: "1.0rem", fontWeight: "600", paddingLeft: "8px", color: "#FF7F01" }}
                      onClick={() => setShowCustomer(true)}
                    >
                      Show Store Info
                    </span>
                  )
                  }
                </div>
                {customer && <CustomerInfo customer={customer} />}
              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Date
                </span> {moment(ticket.date).format('MM-DD-YYYY h:mma')}
              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Items
                </span>
              </Grid>

              <ItemListView items={ticket.items} />


              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "6px", verticalAlign: '-1px' }}>
                  Status
                </span>
                <Badge
                  color={color}
                  size="small"
                  className="px-2"
                  variant="contained"
                >
                  {ticket.urgency === 'Urgent' ? ticket.status + " (Urgent)" : ticket.status}
                </Badge>
              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  ETA
                </span> {ticket.eta ? moment(ticket.eta).format('MM-DD-YYYY') : 'unknown'}
              </Grid>
              <Notes ticket={ticket} />
            </Grid>

          {ticket.status !== "Closed" &&(
            <CreateInvoiceButton ticket={ticket} className={classes.backButton} history={props.history} />
          )}

          </React.Fragment>
          <div style={{ display: "flex", "width": "100%" }}>

            <BackButton history={props.history} />

            {user.role !== "hauler" && (
              <DeleteTicketButton className={classes.backButton} ticket={ticket} history={props.history}/>
            )}

            {((props.cognito && props.cognito.role === 'admin') || ticket.status !== 'Closed') && <Button
              style={{ marginLeft: "auto", marginTop: "10px", marginRight: "10px" }}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={() => props.history.push('/update_ticket?ticket_id=' + ticket.ticket_id)}
            >
              Update
            </Button>}
          </div>
        </Paper>
      </Grid>
    ) : (<Loader isSmall={props.isSmall} />)
  );
}
