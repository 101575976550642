/****************************************************/
// Customers.js
//
// A table of all customers in the database.
// Rows:
// Name|City|Emails|After Hours|Rates|Last Pickup|
/****************************************************/
import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from "@material-ui/core/styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";

import useStyles from "./styles";
import {getMuiTheme, options, columns } from "./theme";

import {customersContext} from "../../context/Customers";
import {userContext} from "../../context/User";

function convertDateTime(dt) {
  return new Date(dt).toLocaleDateString({
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export default function Customers(props) {

  const classes = useStyles();
  const customers = useContext(customersContext);
  const user = useContext(userContext);

  // state
  const [customersTable, setCustomersTable] = useState([]); // data for mui table

  // init
  useEffect(() => {
    if (!customers) return; // wait for context to populate

      // build array for mui table
      const customersTable = Object.values(customers).map((customer) => {

        console.log(customer);
        // show rates as $X.XX
        let rates = Object.entries(customer.rates).map(([key, value]) => {
          const formatted = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(value);

          return (
            <li style={{ listStyleType: "none" }} key={key}>
              {key + ": " + formatted}
            </li>
          );
        });

        return [
          customer.customer_number,
          customer.name,
          customer.city,
          customer.after_hours ? `\u2705` : `\u274C`, // check mark, x mark
          rates,
          customer.last_pickup ? convertDateTime(customer.last_pickup) : "",
        ];
      });
      setCustomersTable(customersTable);
    }
  , [customers]);

  const buttons = [];
  
  // admins can create customers
  if (user && user.role === "admin") {
    buttons.push({
      text: "Add Account",
      icon: <AddIcon className={classes.icon} />,
      func: () => props.history.push("/create_customer"),
    });
  }

  return (
    <React.Fragment>
      <PageTitle title="Accounts" buttons={buttons.length ? buttons : null} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!customersTable.length ? <Loader isSmall={props.isSmall} /> : null}
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Accounts"
              data={customersTable}
              columns={columns}
              options={{
                ...options,
                onRowClick: (_, index) => {
                // get ticket_id from data (based on index)
                const customer_id = Object.keys(customers)[index.dataIndex];
                props.history.push("/customer?customer_id=" + customer_id);
                }
              }}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
