import React, { useContext } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
	Person as PersonIcon,
  Receipt as TicketIcon,
  QuestionAnswer as SupportIcon,
  ArrowBack as ArrowBackIcon,
  Description as InvoiceIcon,
  Store as StoreIcon,
  Assessment as ReportIcon,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import {userContext} from  "../../context/User";

function Sidebar({ props, location }) {
  var classes = useStyles();
	const user = useContext(userContext);

  const structure = [
    { id: 1, label: "Tickets", link: "/tickets", icon: <TicketIcon /> },
    { id: 2, label: "Invoices", link: "/invoices", icon: <InvoiceIcon /> },
    { id: 3, label: "Accounts", link: "/customers", icon: <StoreIcon /> },
    { id: 4, label: "Users", link: "/users", icon: <PersonIcon /> },
    { id: 5, label: "Reports", link: "/reports", icon: <ReportIcon /> },
    { id: 6, type: "divider" },
    { id: 7, type: "title", label: "HELP" },
    { id: 8, label: "Support", link: "email", icon: <SupportIcon /> },
  ];

  // remove accounts and reports for customers
  if (user && user.role === 'customer')
    structure.splice(2, 3);

  // remove reports and users for haulers
  if (user && user.role === 'hauler')
    structure.splice(3, 2);

  return (
    <Drawer
      variant={!props.isSmall ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: props.isSidebarOpen,
        [classes.drawerClose]: !props.isSidebarOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: props.isSidebarOpen,
          [classes.drawerClose]: !props.isSidebarOpen,
        }),
      }}
      open={props.isSidebarOpen}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => props.setIsSidebarOpen(!props.isSidebarOpen)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={props.isSidebarOpen}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );
}

export default withRouter(Sidebar);
