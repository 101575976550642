import React, { useState, useEffect } from "react";
import {
	Grid,
	CircularProgress,
	Typography,
	Button,
	Tabs,
	Tab,
	TextField,
	Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";

// styles
import useStyles from "./styles";

// logo
import logo from "../../assets/logo.svg";

function Login(props) {
	var classes = useStyles();

	// local
	var [isLoading, setIsLoading] = useState(false);
	var [error, setError] = useState("");
	var [activeTabId, setActiveTabId] = useState(0);
	var [email, setEmail] = useState("");
	var [passwordValue, setPasswordValue] = useState("");
	var [valid, setValid] = useState(false);
	var [UID, setUID] = useState("");

	// when you switch tabs clear any active errors
	useEffect(() => {
		setError("");
		setEmail("");
		setPasswordValue("");
	}, [activeTabId])

	return (
		<Grid container className={classes.container}>

			<div className={classes.logotypeContainer}>
				<img src={logo} alt="logo" className={classes.logotypeImage} />
				<Typography className={classes.logotypeText}>FacilityChex</Typography>
			</div>

			<div className={classes.formContainer}>
				<div className={classes.form}>

					<Tabs
						value={activeTabId}
						onChange={(_, id) => setActiveTabId(id)}
						indicatorColor="primary"
						textColor="primary"
						centered
					>
						<Tab label="Login" classes={{ root: classes.tab }} />
						<Tab label="New User" classes={{ root: classes.tab }} />
					</Tabs>

					{activeTabId === 0 && (
						<React.Fragment>
							<Fade in={error ? true : false}>
								<Typography color="secondary" className={classes.errorMessage}>
									{error}
								</Typography>
							</Fade>

							<form>
								<TextField
									id="email"
									InputProps={{
										classes: {
											underline: classes.textFieldUnderline,
											input: classes.textField,
										},
									}}
									value={email}
									onChange={e => {
										setEmail(e.target.value)
									}}
									margin="normal"
									placeholder="Email"
									type="email"
									fullWidth
								/>

								<TextField
									id="password"
									InputProps={{
										classes: {
											underline: classes.textFieldUnderline,
											input: classes.textField,
										},
									}}
									value={passwordValue}
									onChange={e => setPasswordValue(e.target.value)}
									margin="normal"
									placeholder="Password"
									type="password"
									fullWidth
								/>

								<div className={classes.formButtons}>
									{isLoading ? (
										<CircularProgress size={26} className={classes.loginLoader} />
									) : (
											<Button
												disabled={
													email.length === 0 || passwordValue.length === 0
												}
												onClick={async () => {
													setIsLoading(true);
													try {
														await Auth.signIn(email, passwordValue);
														props.userHasAuthenticated(true);
													} catch (e) {
														setError(e.message);
														setIsLoading(false);
													}
												}}
												variant="contained"
												color="primary"
												size="large"
												type='submit'
											>
												Login
											</Button>
										)}

									<Button
										color="primary"
										size="large"
										className={classes.forgetButton}
										onClick={() => props.history.push('/forgot')}
									>
										Forgot Password
                </Button>

								</div>
							</form>
						</React.Fragment>
					)}

					{activeTabId === 1 && (
						<React.Fragment>

							<Fade in={error ? true : false}>
								<Typography color="secondary" className={classes.errorMessage}>
									{error}
								</Typography>
							</Fade>

							<form>

								<TextField
									id="email"
									InputProps={{
										classes: {
											underline: classes.textFieldUnderline,
											input: classes.textField,
										},
									}}
									value={email}
									onChange={e => {
										setEmail(e.target.value);
										if (e.target.value !== "") {
											API.get("api", "/validate_email?email=" + e.target.value).then((resp) => {
												console.log(resp)
												if (resp){
													setValid(true);
													setUID(resp);
												}
											})
										}
									}}
									margin="normal"
									placeholder="Email"
									type="email"
									fullWidth
								/>
								<TextField
									id="password"
									InputProps={{
										classes: {
											underline: classes.textFieldUnderline,
											input: classes.textField,
										},
									}}
									value={passwordValue}
									onChange={e => setPasswordValue(e.target.value)}
									margin="normal"
									placeholder="Password"
									type="password"
									fullWidth
								/>
								<div className={classes.creatingButtonContainer}>
									{isLoading ? (
										<CircularProgress size={26} />
									) : (
											<Button
												onClick={async (e) => {
													e.preventDefault(); // prevent reload
													setError("");
													setIsLoading(true);
													if (!valid) {
														alert("Invalid email.");
														setPasswordValue("");
														setIsLoading(false);
														return
													}

													try {
														const data = {
															username: email,
															password: passwordValue,
															attributes : {
																"custom:uid" : UID,
															}
														};

														await Auth.signUp(data);
														await Auth.signIn(email, passwordValue);
														props.userHasAuthenticated(true);


													} catch (e) {
														setError(e.message);
														setIsLoading(false);
													}
												}}
												disabled={
													email.length === 0 ||
													passwordValue.length === 0
												}
												size="large"
												variant="contained"
												color="primary"
												fullWidth
												className={classes.createAccountButton}
												type='submit'
											>
												Create account
											</Button>
										)}
								</div>
							</form>
						</React.Fragment>
					)}
				</div>
				<Typography color="primary" className={classes.copyright}>
					© 2020 FacilityChex. All rights reserved.
        </Typography>
			</div>
		</Grid>
	);
}

export default withRouter(Login);
