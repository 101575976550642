import React from "react";
import { Grid } from "@material-ui/core";

export function ItemListView({ items }) {
	return (
		<Grid item container xs={12}>
			<Grid item container xs={12}>
				<Grid item xs={3} style={{ fontWeight: "600" }}>Rate Type</Grid>
				<Grid item xs={3} style={{ fontWeight: "600" }}>Price</Grid>
				<Grid item xs={3} style={{ fontWeight: "600" }}>Quantity</Grid>
				<Grid item xs={3} style={{ fontWeight: "600" }}>Inside</Grid>
			</Grid>
			{
				items.map((item) => (
					<Grid item container xs={12}>
						<Grid item xs={3}>{item.rate}</Grid>
						<Grid item xs={3}>{item.price}</Grid>
						<Grid item xs={3}>{item.quantity}</Grid>
						<Grid item xs={3}>{item.inside ? '\u2705' : '\u274c'}</Grid>
					</Grid>
				))
			}
		</Grid>
	);
}

export function ItemView({ item }) {
	return (
		<Grid item container xs={12}>
			<Grid item xs={3}>{item.rate}</Grid>
			<Grid item xs={3}>{item.price}</Grid>
			<Grid item xs={3}>{item.quantity}</Grid>
			<Grid item xs={3}>{item.inside ? '\u2705' : '\u274c'}</Grid>
		</Grid>
	);
}
