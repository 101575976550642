import React, { useEffect, useState, useContext } from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { API } from "aws-amplify";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import moment from "moment";

// libs
import { querystring } from "../../libs/querystring"

// components
import Loader from "../../components/Loader/Loader";

// styles
import useStyles from "./styles";

import { userContext } from "../../context/User";

export default function Invoice(props) {
  const user = useContext(userContext);
  const classes = useStyles();

  //state
  const [invoice, setInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    if (!user) return;
    console.log(user);
    setIsLoading(true);

    // get invoice id from url
    const invoice_id = querystring('invoice_id');
    const params = { queryStringParameters: { user_id: user.user_id } };

    // fetch invoice from ddb
    API.get('api', 'invoices/' + invoice_id).then(resp => {
      setInvoice(resp);
      API.get('api', 'customers/' + resp.customer_number, params).then(custResp => {
        setCustomer(custResp);
        setIsLoading(false);
      })
    }).catch(() => console.log('here')); // TODO: handle error

  }, [user]);

  return (
    invoice && user && !isLoading ? (
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Pickup Invoice
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <div>
                  <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                    Acct#
                  </span> {invoice.customer_number}
                  {!showCustomer && (
                    <span
                      style={{ cursor: 'pointer', fontSize: "1.0rem", fontWeight: "600", paddingLeft: "8px", color: "#FF7F01" }}
                      onClick={() => setShowCustomer(true)}
                    >
                      Show Store Info
                    </span>
                  )
                  }
                </div>
                {customer && showCustomer && (
                  <React.Fragment>
                    <div style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                        Name
                      </span> {customer.name}
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                        City
                      </span> {customer.city}
                    </div>
                    <div style={{ paddingLeft: "10px" }}>
                      <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                        Address
                      </span> {customer.address}
                    </div>
                    {
                      customer.emails && (
                        <div style={{ paddingLeft: "10px" }}>
                          <span style={{ fontWeight: "600", paddingRight: "15px" }}>
                            Contact
                        </span>
                          <div style={{ display: "inline-flex", width: "50%" }}>
                            <div style={{ listStyleType: "none" }}>
                              {
                                customer.emails.map((email) => (
                                  <li>{email}</li>
                                ))
                              }
                            </div>
                          </div>
                        </div>)
                    }
                  </React.Fragment>
                )
                }
              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Date
                </span> {moment(invoice.date).format('MM-DD-YYYY h:mma')}
              </Grid>

              <Grid item xs={12} style={{ fontSize: "1.15rem" }}>
                <span style={{ fontWeight: "600", paddingRight: "3px" }}>
                  Items
                </span>
              </Grid>

              <Grid item container xs={12}>

                <Grid item container xs={12}>
                  <Grid item xs={3} style={{ fontWeight: "600" }}>Rate Type</Grid>
                  <Grid item xs={3} style={{ fontWeight: "600" }}>Price</Grid>
                  <Grid item xs={3} style={{ fontWeight: "600" }}>Quantity</Grid>
                  <Grid item xs={3} style={{ fontWeight: "600" }}>Inside</Grid>
                </Grid>

                {
                  invoice.items.map((item) => (
                    <Grid item container xs={12}>
                      <Grid item xs={3}>{item.rate}</Grid>
                      <Grid item xs={3}>{item.price}</Grid>
                      <Grid item xs={3}>{item.quantity}</Grid>
                      <Grid item xs={3}>{item.inside ? '\u2705' : '\u274c'}</Grid>
                    </Grid>
                  ))
                }

              </Grid>
              <Grid item xs={12}>
                <div style={{ fontWeight: "600", paddingBottom: "30px" }}>Signature</div>
                <img alt="invoice signature" src={invoice.signature} />
              </Grid>
            </Grid>
          </React.Fragment>
          <div style={{ display: "flex", "width": "100%" }}>
            <span
              style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
              onClick={() => props.history.goBack()}
            >
              <BackIcon />
              <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
                Back
              </span>
            </span>
            {(user.role === "admin" || user.user_id === invoice.author_id) && (
              <Button
                style={{ marginLeft: "auto", marginTop: "10px", "backgroundColor": "#F44336" }}
                variant="contained"
                color="secondary"
                size="large"
                className={classes.backButton}
                onClick={() => {
                  API.del('api', 'invoices_delete/' + invoice.invoice_id).then(resp => {
                    props.history.push("/invoices");
                  })
                }}
              >
                Delete
              </Button>
            )
            }
            {!invoice.paid && (
              <Button
                style={{ marginLeft: "auto", marginTop: "10px", "backgroundColor": "#F44336" }}
                variant="contained"
                color="secondary"
                size="large"
                className={classes.backButton}
                onClick={() => {
                  API.post(
                    "api",
                    "update_invoice/" + invoice.invoice_id,
                    { body: { ...invoice, paid: true } }
                  ).then(() => {
                    props.history.push("/invoices");
                  });
                }}
              >
                Mark as Paid
              </Button>
            )
            }
          </div>
        </Paper>
      </Grid>
    ) : (<Loader isSmall={props.isSmall} />)
  );
}
