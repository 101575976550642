import React from "react";
import { useState, createContext, useContext, useEffect } from "react"
import { API } from "aws-amplify";
import { userContext } from "./User";

export const customersContext = createContext(null);



export default function CustomersProvider(props) {
	const [customers, setCustomers] = useState(null);
	const user = useContext(userContext);

	// when user data changes, load data
	useEffect(() => {
		if (!user) return;

		const params = { queryStringParameters: { user_id: user.user_id } }

		// get all customers' information for admins and haulers
		if (user.role === "admin" || user.role === "hauler") {

			let customerMap = {};
			API.get("api", "customers/").then(async (resp) => {
				resp.forEach((cust) => customerMap[cust.customer_id] = cust)
				setCustomers(customerMap)
			}).catch((err) => {
				console.log(`Error loading customers ${err}`);
			});

		} else { //get customer info for user's accounts
			async function fetchCustomerData() {
				let userCustomers = {};
				const accountPromises = user.accounts.map(async (customer) => {
					const customerResp = await API.get("api", "customers/" + customer, params);
					userCustomers[customerResp.customer_id] = customerResp;
				});
				await Promise.all(accountPromises);
				setCustomers(userCustomers);
			}
			fetchCustomerData();
		}
	}
		, [user]);

	return (
		<customersContext.Provider value={customers}>
			{props.children}
		</customersContext.Provider>
	);

}

