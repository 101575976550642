
import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";

export function CustomerInfo({ customer }) {
	return (
		<Grid item container xs={12}>
			<Grid item xs={12}>
				<Typography style={{ display: "inline", fontWeight: "600", paddingRight: "10px" }}>
					Name
				</Typography> {customer.name}
			</Grid>

			<Grid item xs={12}>
				<Typography style={{ display: "inline", fontWeight: "600", paddingRight: "10px" }}>
					City
				</Typography> {customer.city}
			</Grid>

			<Grid item xs={12}>
				<Typography style={{ display: "inline", fontWeight: "600", paddingRight: "10px" }}>
					Address
				</Typography> {customer.address}

			</Grid>
			{
				customer.emails && (
					<Grid item container xs={12}>
						<Typography style={{ display: "inline", fontWeight: "600", paddingRight: "10px" }}>
							Contact
						</Typography>
						<Box style={{ display: "inline-flex", width: "50%", listStyle: "none" }}>
							{
								customer.emails.map((email) => (
									<li>{email}</li>
								))
							}

						</Box>
					</Grid>
				)
			}
		</Grid>
	);
}
