import React, { useState, useEffect, useContext, useRef } from "react";

import currency from "currency.js"

import { API } from "aws-amplify";
import { querystring } from "../../libs/querystring"

import { userContext } from "../../context/User";
import { usersContext } from "../../context/Users";
import { customersContext } from "../../context/Customers";

// mui
import {
  Grid, Paper, Typography, Button, CircularProgress,
  FormControl, Checkbox, FormControlLabel, MenuItem, Select, Box,
  InputLabel,
} from "@material-ui/core";
import AutoComplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Clear, ArrowBackIos as BackIcon, Add } from "@material-ui/icons";

import SignaturePad from "react-signature-canvas"

// styles
import useStyles from "./styles";

import { checkRate, checkQuantity } from "../../libs/Errors";

export default function Create(props) {

  const classes = useStyles();
  const signatureCanvas = useRef({});

  const user = useContext(userContext);
  const users = useContext(usersContext);
  const customers = useContext(customersContext);

  //state
  const [customer, setCustomer] = useState({
    rates: { "standard": 0.00 }
  });

  const [customerNum, setCustomerNum] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [taxRate, setTaxRate] = useState('');

  const [date, setDate] = useState(new Date(Date.now()));
  const [dateErr, setDateErr] = useState(false);
  const [customerNumErr, setCustomerNumErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fromTicket, setFromTicket] = useState(false);

  const [isPaid, setIsPaid] = useState(false);
  const [checkNum, setCheckNum] = useState("");

  const [ticket, setTicket] = useState({});
  const [items, setItems] = useState([])

  const [newRate, setNewRate] = useState('');
  const [newPrice, setNewPrice] = useState(0);
  const [newQuantity, setNewQuantity] = useState(0);
  const [newInside, setNewInside] = useState(false);

  useEffect(() => {
    if (!user || !customers) return;
    setIsLoading(true);
    console.log(customers);

    // get ticket id from url
    const ticket_id = querystring('ticket_id');

    // fetch ticket from ddb
    if (ticket_id) {
      setFromTicket(true);
      let params = { queryStringParameters: { user_id: user.user_id } };
      API.get('api', 'tickets/' + ticket_id, params).then(resp => {
        setItems(resp.items);
        setTicket(resp);

        setCustomerId(resp.customer_id);
        setCustomerNum(resp.customer_number);
        setCustomerName(customers[resp.customer_id].name);
        setTaxRate(customers[resp.customer_id].tax_rate);
        setIsLoading(false);
      }).catch(() => console.log('Failed to retrieve ticket')); // TODO: handle error
    } else {
      setIsLoading(false);
      if (user.role === "customer") {
        setCustomer(Object.values(customers)[0]);
        setCustomerNum(Object.values(customers)[0].customer_number);
        setCustomerName(Object.values(customers)[0].name);
        const tax = Object.values(customers)[0].tax_rate;
        setTaxRate(tax ? tax : 0);
      }
    }
  }, [user, customers]);

  function handleCreate() {
    // reset form errors
    setDateErr(false);
    setCustomerNumErr(false);

    // check store num
    if (!customerNum) {
      setCustomerNumErr(true);
      return;
    }

    // get date
    if (!date || date.toString() === 'Invalid Date') {
      setDateErr(true);
      return;
    }

    let updatedItems = items.map((item) => (
      {
        ...item,
        "price": currency(item.price).value,
        "quantity": parseInt(item.quantity),
      }
    ))

    // create invoice
    API.post("api", "create_invoice", {
      body: {
        items: updatedItems,
        customer_number: customerNum.padStart(4, '0'),
        customer_id: customerId,
        author_id: user.user_id,
        customer_name: customerName,
        signature: signatureCanvas.current.getTrimmedCanvas().toDataURL('image/png'),
        paid: isPaid,
        check_number: isNaN(parseInt(checkNum)) ? 0 : parseInt(checkNum),
        tax_rate: currency(taxRate).value,
        date: date.toISOString(),
      }
    }).then(() => {

      // update last pickup date for the customer if invoice is 
      // dated after their current last pickup
      // note: any date is "newer" than null
      if (date > customer.last_pickup) {
        API.post("api", "update_customer/" + customerId, {
          body: { last_pickup: date.toISOString() }
        })
      }

      if (!fromTicket) {
        props.history.push('/invoices');
        return;
      }


      const newItems = [];

      // Get the items left that haven't been picked up
      ticket.items.forEach((ticket_item) => {
        const invoice_item = items.find((item) => (item.inside === ticket_item.inside && item.rate === ticket_item.rate));
        newItems.push({ ...ticket_item, quantity: parseInt(ticket_item.quantity) - parseInt(invoice_item.quantity) });
      });

      // find the total quantity of items not picked up
      let total = newItems.reduce((totalQuantity, item) => {
        return totalQuantity + item.quantity
      }, 0)
      
      // if there are at least 30 items left at the store, create a new ticket
      if (total >= 30) {
        const new_ticket = {
          items: newItems,
          role: 'admin',
          customer_id: customerId,
          customer_number: customerNum,
          customer_name: customerName,
          note: `Created by system due to high leftover quantity (${total})`
        };
        API.post("api", "create_ticket", { body: new_ticket }).then(() => {})
      }

      // update status and notes of this ticket
      API.post(
        "api",
        "update_ticket/" + ticket.ticket_id,
        {
          body: {
            status: "Closed",
            hauler_id:  user.user_id,
            notes: [...ticket.notes, { [date.toISOString() + user.role]: "Invoice created, ticket closed." }],
          }
        }).then(() => {
          props.history.push('/invoices')
        })
    });
  }

  return user && customers && ((user.role === "admin" && users) || user.role !== "admin") && (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Create Invoice for Pickup
          </Typography>
          <Grid container>
            <Grid item spacing={2} container id={"top-row"} style={{ justifyContent: "space-between" }} > { /*+ Top Row */}
              <Grid id={"date-picker"} item xs={12} md={5}> { /*+ Date Picker*/}
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <KeyboardDatePicker
                      error={dateErr}
                      disableToolbar
                      variant="inline"
                      format="MM-dd-yyyy"
                      label="Date"
                      inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                      InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
                      value={date}
                      onChange={e => setDate(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      autoOk={true}
                    />

                  </MuiPickersUtilsProvider>
                </Box>
              </Grid>{/*- Date Picker */}

              <Grid container item xs={12} md={5}> {/*+ Customer Number */}
                {
                  user.role === "admin" || user.role === "hauler" ?

                    <Grid container item>
                      <Grid item xs={12}>
                        <TextField // admins get a text field where you can enter the Account number
                          required
                          fullWidth
                          disabled={user.role === "customer" || fromTicket}
                          error={customerNumErr}
                          value={customerNum}
                          onChange={async (e) => {

                            setCustomerNum(e.target.value)
                            const params = { queryStringParameters: { user_id: user.user_id } }
                            //check db for existence of customer with this number
                            const resp = await API.get("api", "/customers/" + e.target.value.padStart(4, '0'), params);
                            if (!resp) {
                              setCustomerNumErr(true);
                              return
                            }
                            setCustomer(resp);
                            setCustomerId(resp.customer_id);
                            setCustomerName(resp.name);
                            setCustomerNumErr(false);
                          }}
                          helperText={customerNumErr ? "Invalid Acct#" : "Acct#"}
                          inputProps={{ style: { fontSize: '1.15rem' } }} // font size of input text
                          InputLabelProps={{ style: { fontSize: '1.15rem' } }} // font size of input label
                        />
                        <AutoComplete
                          fullWidth
                          disabled={fromTicket}
                          options={Object.values(customers)}
                          getOptionLabel={(option) => option.name ? option.name : ""}
                          value={customer || null}
                          renderInput={(params) => <TextField style={{ width: "100%" }}{...params} label="Account Name" />}
                          onChange={(event, customer) => {
                            if (customer) {
                              setCustomerNum(customer.customer_number);
                              setCustomerNumErr(false);
                              setCustomer(customer);
                              setCustomerId(customer.customer_id);
                              setCustomerName(customer.name);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    :
                    <FormControl fullWidth>
                      <FormControlLabel
                        labelPlacement="top"
                        control={
                          <Select // regular accounts get a dropdown containing any accounts they own
                            label="Account"
                            value={customerNum}
                            disabled={fromTicket}
                            style={{ marginRight: "20px" }}
                            defaultValue={""}
                            onChange={(e) => {
                              const acct = Object.values(customers).find((acct) => acct.customer_number === e.target.value)
                              if (!acct) {
                                setCustomerNumErr(true);
                                return;
                              }
                              setCustomer(acct);
                              setCustomerNum(acct.customer_number);
                              setCustomerId(acct.customer_id);
                              setCustomerName(acct.name);
                              setItems([]);
                              setNewRate("");
                              setNewQuantity(0);
                              setNewPrice(0);
                            }}
                          >
                            {
                              Object.values(customers).map((val) => {
                                return <MenuItem value={val.customer_number}>{`${val.name} (${val.customer_number}) `}</MenuItem>
                              })
                            }
                          </Select>
                        }
                        label="Account"
                      />
                    </FormControl>
                }
              </Grid> {/*- Customer Number */}
            </Grid>

            <Grid style={{ paddingTop: "30px" }} container id={"current-items"} > {/*+ Current Items */}
              {
                items.map((item, index) => {
                  return (
                    <Grid item container> {/*+ Item row */}

                      <Grid item xs={1}> {/*+ Delete Button */}
                        <Button
                          style={{
                            maxWidth: "20px",
                            maxHeight: "20px",
                            minHeight: "20px",
                            minWidth: "20px",
                            marginTop: "10px"
                          }}
                          variant="outlined"
                          onClick={() => {
                            setItems(items.filter((_, i) => i !== index));
                          }}
                          startIcon={<Clear style={{ marginLeft: "10px" }} />}
                          size="small"
                        />
                      </Grid> {/*- Delete Button */}

                      <Grid item xs={3}>
                        <TextField
                          value={item.rate}
                          disabled
                          error={item.rate === ""}
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              if (i === index) {
                                return { ...item, "rate": e.target.value };
                              }
                              return item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid>

                      <Grid item xs={2}>
                        <TextField
                          value={item.price}
                          disabled
                          error={checkRate(item.price) || item.price === ""}
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              if (i === index) {
                                return { ...item, "price": e.target.value };
                              }
                              return item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid >

                      <Grid item xs={2}>
                        <TextField
                          value={item.quantity}
                          disabled={!fromTicket}
                          error={checkQuantity(item.quantity)}
                          type="number"
                          onChange={(e) => {
                            setItems(items.map((item, i) => {
                              return i === index ? (
                                { ...item, "quantity": e.target.value }
                              ) : item;
                            }))
                          }}
                          style={{ marginRight: "20px", marginTop: "5px" }} />
                      </Grid >

                      <Grid item xs={1}>
                        <FormControl style={{ marginBottom: "5px" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={item.inside}
                                onChange={(e) => {
                                  setItems(items.map((item, i) => {
                                    return i === index ? (
                                      { ...item, "inside": e.target.checked }
                                    ) : item;
                                  }))
                                }}
                              />}
                            label="Inside" />
                        </FormControl>
                      </Grid>

                    </Grid>
                  )
                })
              }
            </Grid>
            {!fromTicket && (
              <Grid container id={"new-item"} style={{ paddingTop: "15px" }} > {/*+ New Item */}

                <Grid item container spacing={2} >

                  <Grid item xs={1}>
                    <Button
                      style={{
                        maxWidth: "20px",
                        maxHeight: "20px",
                        minHeight: "20px",
                        minWidth: "20px",
                        marginTop: "25px",
                      }}
                      disabled={newRate === "" || checkRate(newPrice) || checkQuantity(newQuantity)}
                      variant="outlined"
                      onClick={() => {
                        let match = items.findIndex(item => item.rate === newRate && item.inside === newInside)
                        if (match !== -1) {
                          let temp = items;
                          temp[match].quantity = parseInt(temp[match].quantity) + parseInt(newQuantity)
                          setItems(temp)
                        }
                        else {
                          setItems(
                            [
                              ...items,
                              {
                                rate: newRate,
                                price: newPrice,
                                quantity: newQuantity,
                                inside: newInside
                              }
                            ]
                          )
                        }
                        setNewRate("");
                        setNewPrice(0);
                        setNewQuantity(0);
                      }}
                      startIcon={<Add style={{ marginLeft: "10px" }} />}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    {
                      !customer.rates.hasOwnProperty(newRate) && newRate !== "" ? (
                        <TextField
                          value={newRate === "Add Rate" ? "" : newRate}
                          helperText={"New Rate Name"}
                          error={newRate === ""}
                          onChange={(e) => {
                            if (customer.rates.hasOwnProperty(e.target.value)) {
                              setNewPrice(customer.rates[e.target.value])
                            }
                            setNewRate(e.target.value);
                          }
                          }
                          style={{ marginRight: "20px", marginTop: "15px" }} />) :
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel>Rate</InputLabel>
                          <Select
                            style={{ width: "100%" }}
                            error={newRate === ""}
                            value={newRate}
                            onChange={(e) => {
                              setNewRate(e.target.value)
                              setNewPrice(customer.rates[e.target.value] ? customer.rates[e.target.value] : "0.00")
                            }}
                          >
                            {
                              Object.entries({ ...customer.rates, "Add Rate": "0.00" }).map(([key, value]) => {
                                return (
                                  <MenuItem value={key}>{key}</MenuItem>
                                );
                              })
                            }
                          </Select>
                        </FormControl>
                    }
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      required
                      label="Price"
                      disabled={customer.rates.hasOwnProperty(newRate)}
                      value={newPrice}
                      onChange={(e) => setNewPrice(e.target.value)}
                    />
                  </Grid >

                  <Grid item xs={2}>
                    <TextField
                      required
                      label="Qty."
                      error={checkQuantity(newQuantity)}
                      value={newQuantity}
                      onChange={(e) => setNewQuantity(e.target.value)}
                    />
                  </Grid >

                  <Grid item xs={1}>
                    <FormControl style={{ marginTop: "15px" }}>
                      <FormControlLabel control={<Checkbox onChange={(e) => setNewInside(Boolean(e.target.checked))} checked={(newInside)} />} label="Inside" />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl style={{ paddingTop: "10px", marginBottom: "5px" }}>
                <FormControlLabel
                  style={{ marginLeft: "0px" }}
                  labelPlacement="start"
                  control={
                    <TextField
                      value={taxRate}
                      helperText="decimal"
                      style={{ width: "50px" }}
                      onChange={(e) => {
                        setTaxRate(e.target.value);
                      }}
                    />}
                  label={<Typography style={{ marginRight: "20px", fontSize: "1.20rem" }}>Tax Rate:</Typography>} />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl style={{ marginBottom: "5px" }}>
                <FormControlLabel
                  style={{}}
                  control={
                    <Checkbox
                      checked={isPaid}
                      onChange={(e) => {
                        setIsPaid(e.target.checked);
                      }}
                    />}
                  label={<Typography style={{ fontSize: "1.20rem" }}>Mark as paid</Typography>} />
              </FormControl>
            </Grid>


            {
              isPaid && (
                <Grid item xs={12}>
                  <FormControl style={{ width: "50%", marginBottom: "5px" }}>
                    <FormControlLabel
                      style={{}}
                      labelPlacement="start"
                      control={
                        <TextField
                          value={checkNum}
                          fullWidth
                          helperText="Leave Blank for Cash"
                          onChange={(e) => {
                            setCheckNum(e.target.value);
                          }}
                        />}
                      label={<Typography style={{ marginRight: "20px", fontSize: "1.20rem" }}>Check#:</Typography>} />
                  </FormControl>
                </Grid>
              )
            }
            <div style={{ marginTop: "25px" }}>Signature:</div>
            <SignaturePad
              canvasProps={{
                style: {
                  width: "100%",
                  border: "1px solid black",
                  margin: "25px 0px 15px 0px"
                }
              }}
              ref={signatureCanvas}
            />
            <Button
              className={classes.backButton}
              style={{
                width: "100%",
                height: "30px",
                marginBottom: "20px"
              }}
              onClick={() => { signatureCanvas.current.clear() }}
              startIcon={<Clear style={{ marginLeft: "10px" }} />}
              color="primary"
              variant="contained"
            >
              Clear Signature
            </Button>
          </Grid>
        </React.Fragment>
        <div style={{ display: "flex", "width": "100%" }}>
          <span
            style={{ marginTop: "19px", cursor: "pointer", color: "#FF7F01" }}
            onClick={() => {
              if (isLoading) return;
              props.history.goBack();
            }}
          >
            <BackIcon />
            <span style={{ verticalAlign: "6px", fontSize: "1.1rem" }}>
              Back
            </span>
          </span>
          {isLoading ? (
            <CircularProgress size={26} style={{ marginLeft: "auto", marginTop: "20px" }} />
          ) : (
              <Button
                style={{ marginLeft: "auto", marginTop: "10px" }}
                variant="contained"
                color="secondary"
                size="large"
                className={classes.backButton}
                onClick={handleCreate}
              >
                Confirm
              </Button>
            )}
        </div>
      </Paper>
    </Grid>
  );
}
