import React, { useState } from "react";
import { checkRate, checkEmail } from "../../libs/Errors";
import { Grid, TextField, FormControl, FormControlLabel, InputAdornment, Checkbox, Button } from "@material-ui/core"
import { Clear, Add } from "@material-ui/icons";


function NewRateForm({ customer, setCustomer }) {
  const [newRate, setNewRate] = useState({
    name: "",
    value: "",
  });
  return (
    <Grid container>
      <Grid item xs={1}>
        <Button
          style={{
            maxWidth: "20px",
            maxHeight: "20px",
            minHeight: "20px",
            minWidth: "20px",
            marginTop: "5px",
          }}
          variant="outlined"
          disabled={newRate.name === "" || newRate.value === "" || checkRate(newRate.value)}
          onClick={() => {
            let temp = customer.rates;
            temp[newRate.name] = newRate.value;
            setCustomer((prevCust) => ({
              ...prevCust,
              rates: temp,
            }));
            setNewRate({ name: "", value: "" });
          }}
          startIcon={<Add style={{ marginLeft: "10px" }} />}
          size="small"
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          value={newRate.name}
          placeholder="rate name"
          onChange={(e) => {
            setNewRate({ ...newRate, name: e.target.value });
          }}
        />
      </Grid>

      <Grid item xs={1}>
        <TextField
          value={newRate.value}
          placeholder="rate"
          error={checkRate(newRate.value)}
          style={{ whiteSpace: "pre-wrap" }}
          startadornment={
            <InputAdornment position="start">$</InputAdornment>
          }
          onChange={(e) => {
            setNewRate({ ...newRate, value: e.target.value });
          }}
        />
      </Grid>
    </Grid>
  );
}

function RatesView({ customer, setCustomer }) {
  return (
    <Grid container item xs={12} style={{ fontSize: "1.15rem" }}>
      <FormControl
        style={{ minWidth: "100%" }}
      >
        {
          Object.entries(customer.rates).map(([key, value]) => {
            let helperText = "";
            if (checkRate(value)) helperText = "Invalid Rate";
            return (
              <Grid item container key={key} xs={12}>

                {/*+Delete button*/}
                <Grid item xs={1}>
                  <Button
                    style={{
                      maxWidth: "20px",
                      maxHeight: "20px",
                      minHeight: "20px",
                      minWidth: "20px",
                      marginTop: "5px",
                    }}
                    variant="outlined"
                    size="small"
                    startIcon={
                      <Clear style={{ marginLeft: "10px" }} />
                    }
                    onClick={() => {
                      let temp = { ...customer.rates };
                      delete temp[key];
                      setCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        rates: temp,
                      }));
                    }}
                  />
                </Grid>

                {/*Rate name*/}
                <Grid item xs={5}>
                  <span>{key}</span>
                </Grid>

                {/*Rate Value*/}
                <Grid item xs={1}>
                  <TextField
                    error={checkRate(value)}
                    helperText={helperText}
                    value={value}
                    onChange={(e) => {
                      let temp = { ...customer.rates };
                      temp[key] = e.target.value;
                      setCustomer((prevCustomer) => ({
                        ...prevCustomer,
                        rates: temp,
                      }));
                    }}
                  />
                </Grid>

              </Grid>
            );
          })
        }
      </FormControl>
    </Grid>
  );
}

function NewEmailForm({ customer, setCustomer }) {
  const [newEmail, setNewEmail] = useState("");
  return (
    <Grid container>
      {/*Add button*/}
      <Grid item xs={1}>
        <Button
          style={{
            maxWidth: "20px",
            maxHeight: "20px",
            minHeight: "20px",
            minWidth: "20px",
            marginTop: "5px",
          }}
          variant="outlined"
          disabled={newEmail === "" || checkEmail(newEmail)}
          onClick={() => {
            const temp = {
              ...customer,
              emails: [...customer.emails, newEmail],
            };
            setNewEmail("");
            setCustomer(temp);
          }}
          startIcon={<Add style={{ marginLeft: "10px" }} />}
          size="small"
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          value={newEmail}
          error={newEmail !== "" && checkEmail(newEmail)}
          helperText={(newEmail !== "" && checkEmail(newEmail)) ? "Invalid Email" : ""}
          style={{ minWidth: "70%", padding: "0 0 5px 5px" }}
          onChange={(e) => {
            setNewEmail(e.target.value);
          }}
        />
      </Grid>
    </Grid>
  );
}

function EmailsView({ customer, setCustomer }) {

  return (
    <FormControl style={{ minWidth: "100%" }}>
      {
        customer.emails.map((email, index) => {
          let helperText = "";
          if (checkEmail(email)) {
            helperText = "Invalid email";
          }
          return (
            <Grid container>
              {/*Delete Button*/}
              <Grid item xs={1}>
                <Button
                  style={{
                    maxWidth: "20px",
                    maxHeight: "20px",
                    minHeight: "20px",
                    minWidth: "20px",
                    marginTop: "5px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setCustomer((prevCust) => ({
                      ...prevCust,
                      emails: customer.emails.filter(
                        (_, i) => i !== index,
                      ),
                    }));
                  }}
                  startIcon={<Clear style={{ marginLeft: "10px" }} />}
                  size="small"
                />
              </Grid>

              {/*Existing Customer Email*/}
              <Grid item xs={10}>
                <TextField
                  style={{
                    padding: "0 0 5px 5px",
                    minWidth: "70%",
                  }}
                  value={email}
                  error={checkEmail(email)}
                  helperText={helperText}
                  onChange={(e) => {
                    const newEmails = customer.emails.map(
                      (email, i) => {
                        if (i === index) {
                          return e.target.value;
                        } else {
                          return email;
                        }
                      },
                    );
                    setCustomer({
                      ...customer,
                      emails: newEmails,
                    });
                  }}
                />
              </Grid>
            </Grid>
          )
        })}
    </FormControl>
  );
}
function TextRow({ value, onChange, label }) {
  return (
    <Grid container item xs={12} direction="row" alignItems="center">
      <Grid item xs={12} sm={2}>
        <span style={{ width: "100%", fontWeight: 600, }}>{label}:</span>
      </Grid>
      <Grid item xs={12} sm={10}>
        <TextField
          fullWidth
          onChange={onChange}
          value={value}
        />
      </Grid>
    </Grid>
  );
}

function GridCheckBox({ value, label, onChange }) {
  return (
    <Grid item>
      <FormControl fullWidth>
        <FormControlLabel
          control={<Checkbox />}
          label={label}
          onChange={onChange}
          checked={value}
        />
      </FormControl>
    </Grid>
  );
}

export default function CustomerInfoForm({ customer, setCustomer, ...props }) {
  return (
    <Grid item container xs={12} spacing={4}>

      <Grid item container>
        <TextRow
          label="Name"
          value={customer.name}
          onChange={(e) => { setCustomer({ ...customer, name: e.target.value }) }}
        />

        <TextRow
          label="City"
          value={customer.city}
          onChange={(e) => { setCustomer({ ...customer, city: e.target.value }) }}
        />

        <TextRow
          label="Address"
          value={customer.address}
          onChange={(e) => { setCustomer({ ...customer, city: e.target.value }) }}
        />
      </Grid>

      <Grid  container item xs={12}>
        <span style={{ width: "100%", fontWeight: 600 }}>Email Contacts:</span>
        <EmailsView customer={customer} setCustomer={setCustomer} />
        <NewEmailForm customer={customer} setCustomer={setCustomer} />
      </Grid>

      <Grid container item xs={12} direction="row" alignItems="center" justifyContent="space-around">
        <GridCheckBox
          label="After Hours"
          value={customer.after_hours}
          onChange={(e) => { setCustomer({ ...customer, after_hours: e.target.checked }) }}
        />

        <GridCheckBox
          label="Saturday Pickup"
          value={customer.saturday_pickup}
          onChange={(e) => { setCustomer({ ...customer, saturday_pickup: e.target.checked }) }}
        />
      </Grid>

      <Grid container item xs={12} direction="row" alignItems="center">
        <Grid item xs={12} sm={4}>
          <span style={{ width: "100%", fontWeight: 600 }}>Tax Rate (as decimal):</span>
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            style={{ width: "20%" }}
            value={customer.tax_rate}
            onChange={(e) => { setCustomer({ ...customer, tax_rate: e.target.value }) }}
          />
        </Grid>
      </Grid>


      <Grid container item xs={12}>
        <Grid item xs={12}>
          <span style={{ width: "100%", fontWeight: 600 }}>Rates:</span>
        </Grid>
        <RatesView customer={customer} setCustomer={setCustomer} />
        <NewRateForm customer={customer} setCustomer={setCustomer} />
      </Grid>

    </Grid>
  );
}
