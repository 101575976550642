import React from "react";
import { useState, createContext, useContext, useEffect } from "react"
import { API } from "aws-amplify";
import { userContext } from "./User";

export const usersContext = createContext(null);


export default function UsersProvider(props) {
  const [users, setUsers] = useState(null);
	const user = useContext(userContext);

  // when cognito data changes, load 
  useEffect(() => {
    if (!user) return;

    // get user table entry
		const params = { queryStringParameters: { user_id : user.user_id} }
    API.get("api", "users/", params)
      .then(async (usersResp) => {

        if (!usersResp)
          return;

				let usersMap = {};
				usersResp.forEach((user) => usersMap[user.user_id] = user);
        setUsers(usersMap);

      }).catch((err) => {
        console.log(`Error creating user context: ${err}`);
      })
  }
    , [user]);

  return (
    <usersContext.Provider value={users}>
      {props.children}
    </usersContext.Provider>
  );

}

