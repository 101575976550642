import React from "react";
import { useState, createContext, useEffect } from "react"
import { API } from "aws-amplify";

export const userContext = createContext(null);


export default function UserProvider(props) {
  const [user, setUser] = useState(null);

  // when cognito data changes, load 
  useEffect(() => {
    if (!props.cognito) return;
		console.log(props.cognito);

    // get user table entry
		const params = { queryStringParameters: { user_id : props.cognito.UID } }
    API.get("api", "users/" + props.cognito.UID, params)
      .then(async (userResp) => {

        if (!userResp)
          return;

        setUser(userResp);

      }).catch((err) => {
        console.log(`Error creating user context: ${err}`);
      })
  }
    , [props.cognito]);

  return (
    <userContext.Provider value={user}>
      {props.children}
    </userContext.Provider>
  );

}

